import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Buffer } from 'buffer';

export const EncryptionPublicKey = new InjectionToken<string>('exactix.encryption.publickey');

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {

    private _PublicKey: CryptoKey;

    constructor(@Inject(EncryptionPublicKey) @Optional() private KEY: string) {
        //  The encryption key comes in the amplifyConfig.json and is "provided" in main.ts so that it can be configured
        //  in the environment and so that it is available as soon as the app starts up.
        this.ImportRSAPublicKey();
    }

    /**
     * Encrypt the given payload and return the encrypted ciphertext and key.
     * @param payload
     * @returns
     */
    public async Encrypt(payload: any): Promise<{ encryptedPayload: ArrayBuffer, key: string }> {
        //  https://stackoverflow.com/questions/62948516/using-native-javascript-subtlecrypto-to-encrypt-using-rsa

        //console.warn("Encrypt: payload = ", payload);

        //  Encrypt the payload using AES.  This generates a random AES key and IV which is returned with the ciphertext.
        //  encrypt using AES: https://medium.com/@tony.infisical/guide-to-web-crypto-api-for-encryption-decryption-1a2c698ebc25
        const aesInfo = await this.EncryptToAES(payload);
        //console.warn("Encrypt: aesInfo = ", aesInfo);

        //  Append the byte arrays for both the AES key and IV together.
        //  Then encrypt that using our RSA public key.  Once this is done, only the RSA private key can decrypt it
        //  which can only be done on the server.  So it is safe to send this to the server and no one else can decrypt it.
        //  These values are needed by the server in order to decrypt the AES ciphertext.
        const keyData = Buffer.concat([aesInfo.key, aesInfo.iv]);
        const encryptedKeyData = await window.crypto.subtle.encrypt({ name: "RSA-OAEP" }, this._PublicKey, keyData);

        return {
            encryptedPayload: aesInfo.encryptedPayload,
            key: Buffer.from(encryptedKeyData).toString("base64")
        };
    }

    //  AES encrypt/decrypt from here: https://medium.com/@tony.infisical/guide-to-web-crypto-api-for-encryption-decryption-1a2c698ebc25

    private async EncryptToAES(payload: object): Promise<{ encryptedPayload: ArrayBuffer, key: Uint8Array, iv: Uint8Array }> {
        const json = JSON.stringify(payload);
        const data = new TextEncoder().encode(json);

        // Create a random encryption key
        const key = crypto.getRandomValues(new Uint8Array(32));     //  TODO: Could also include a timestamp in here that can be verified on the server

        // Create a random 96-bit initialization vector (IV)
        const iv = crypto.getRandomValues(new Uint8Array(12));

        // prepare the secret key for encryption
        const algorithm: AesKeyAlgorithm = {
            name: 'AES-GCM',
            length: 256
        };
        const secretKey = await crypto.subtle.importKey('raw', key, algorithm, true, ['encrypt', 'decrypt']);
        //console.warn("EncryptToAES: secretKey", secretKey);

        // encrypt the data with the secret key
        const aesParams: AesGcmParams = {
            name: 'AES-GCM',
            iv: iv,
            tagLength: 128     //  128bits = 16bytes - the tag is appended to the end of the cipherText!
        };
        const encryptedPayload = await crypto.subtle.encrypt(aesParams, secretKey, data);
        //console.warn("EncryptToAES: encrypt", data, encryptedData);

        // return the encrypted text "ciphertext" and the IV encoded in base64
        return ({
            //  The authentication tag is appended to the end of the cipherText!
            encryptedPayload: encryptedPayload,
            key: key,
            iv: iv
        });
    }

    private async DecryptFromAES(ciphertext: string, key: string, iv: string): Promise<object> {
        // prepare the secret key
        const secretKey = await crypto.subtle.importKey('raw', Buffer.from(key, 'base64'), {
                name: 'AES-GCM',
                length: 256
            }, true, ['encrypt', 'decrypt']);

        // decrypt the encrypted text "ciphertext" with the secret key and IV
        const cleartext = await crypto.subtle.decrypt({
            name: 'AES-GCM',
            iv: Buffer.from(iv, 'base64'),
        }, secretKey, Buffer.from(ciphertext, 'base64'));

        // decode the text and return it
        const decoded = new TextDecoder().decode(cleartext);
        return JSON.parse(decoded);
    }

    private ImportRSAPublicKey(): void {
        const keyData = Buffer.from(this.KEY, "base64");
        //console.warn("ImportKey: keyData=", keyData);

        window.crypto.subtle.importKey("spki", keyData, { name: "RSA-OAEP", hash: "SHA-256" }, true, ["encrypt"])
            .then((publicKey) => {
                //console.warn("ImportKey: publicKey = ", publicKey);
                this._PublicKey = publicKey;
            }).catch((err) => {
                console.warn("ImportKey: error= ", err);
                console.error(err);
            });
    }
}

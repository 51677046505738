import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GlobalErrorHandler } from 'Interceptors/GlobalErrorHandler';
import { IqAwsCognitoModule } from 'iqCognito/iq-aws-cognito.module';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { ToastrModule } from 'ngx-toastr';
import { GoogleResponseComponent } from 'Pages/Authentication/GoogleResponsePage/GoogleResponsePage.component';
import { LoginComponent } from 'Pages/Authentication/Login/Login.component';
import { AdminLoginControls } from 'Pages/Authentication/LoginControls/AdminLoginControls.module';
import { ExactixChangeVerifyEmailDialogComponent } from 'Pages/Authentication/LoginControls/ChangeVerifyEmailDialog/ChangeVerifyEmailDialog.component';
import { ExactixResetPasswordComponent } from 'Pages/Authentication/LoginControls/ForgotPasswordView/ForgotPasswordView.component';
import { ExactixLinkPersonSignUpComponent } from 'Pages/Authentication/LoginControls/LinkPersonSignUp/LinkPersonSignUp.component';
import { LogoutComponent } from 'Pages/Authentication/Logout/Logout.component';
import { NoPersonForLoginComponent } from 'Pages/Authentication/NoPersonForLogin/NoPersonForLogin.component';
import { SwitchCenterComponent } from 'Pages/Authentication/SwitchCenter/SwitchCenter.component';
import { ServiceAreaAnonymousModule } from "Pages/ServiceAreas/Anonymous/ServiceAreaAnonymous.module";
import { TicketAnonymousModule } from 'Pages/Tickets/Anonymous/TicketAnonymous.module';
import { UserRegistrationModule } from 'Pages/UserRegistration/UserRegistration.module';
import { GoogleGtagModule } from "Services/GoogleAnalytics/google-gtag.module";
import { AnnouncementsCarouselModule } from 'Shared/Components/Controls/AnnouncementsCarousel/AnnouncementsCarousel.module';
import { MatPaginatorIntl4IQ } from 'Shared/Modules/Material.module';
import { PhoneHeaderModule } from 'Shared/PhoneDevice/Header/PhoneHeader.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DesktopRootComponent } from './DesktopRoot.component';
import { LinkUserToPersonComponent } from './Pages/Authentication/LinkUser/LinkUserToPerson.component';
import { HomeComponent } from './Pages/Home/Home.component';
import { ProfileVerificationsModule } from './Pages/ProfileVerifications/Modules/ProfileVerifications.module';
import { PhoneRootComponent } from './PhoneRoot.component';
import { ChatComponent } from './Shared/Components/Chat/Chat.component';
import { ToggleFullscreenDirective } from "./Shared/Directives/ToggleFullScreen.directive";
import { GlobalSharedModule } from './Shared/Modules/GlobalShared.module';
import { FourIQCustomToast } from "./Shared/Utils/CustomToast";

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule.withConfig({
            //  Disable animations if not supported (on iPhone 6 / Safari 13)
            //  https://github.com/angular/angular/issues/45016
            disableAnimations:
                !('animate' in document.documentElement) ||
                (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent)),
        }),
        AppRoutingModule,
        GlobalSharedModule,
        //  See: https://github.com/scttcper/ngx-toastr for configuration and options
        ToastrModule.forRoot({
            //toastComponent: ToastNoAnimation,
            toastComponent: FourIQCustomToast,
            positionClass: 'toast-top-right-below-toolbar',
            closeButton: true,
            maxOpened: 5,
            autoDismiss: true,
            progressBar: true,
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
            extendedTimeOut: 3000 //  Default on this is 1 sec - affects timeout if user hovers.  If no hover, default=5 secs.
        }),
        AnnouncementsCarouselModule,
        ProfileVerificationsModule,
        TicketAnonymousModule,
        ServiceAreaAnonymousModule,
        IqAwsCognitoModule.forRoot(),
        PhoneHeaderModule,
        //  At least for now, using EnableServiceWorker flag in environment to turn on the Service Worker.
        //  Should just use "production" but this allows us to turn it off easier if we have problems with it.
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.EnableServiceWorker /*production*/ /*, registrationStrategy: 'registerImmediately'*/ }),
        GoogleGtagModule,
        AdminLoginControls,
        UserRegistrationModule,
        ChatComponent,
        RecaptchaV3Module
    ],
    declarations: [
        AppComponent, DesktopRootComponent, PhoneRootComponent,
        HomeComponent,
        ToggleFullscreenDirective,
        LinkUserToPersonComponent, NoPersonForLoginComponent,
        SwitchCenterComponent,
        GoogleResponseComponent,
        LoginComponent, LogoutComponent,
        ExactixChangeVerifyEmailDialogComponent,
        ExactixResetPasswordComponent,
        ExactixLinkPersonSignUpComponent,
        FourIQCustomToast,
    ],
    providers: [
        Title,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntl4IQ },
        //  This is needed so that the overlay (which is used to show menus and autocomplete lists) works correctly with
        //  fullscreen.  Without this, these things do not display correctly in the Map Search if the Map is put into fullscreen.
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
